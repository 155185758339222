import { WorkFlowTaskModel, WorkflowTaskStepType } from "components/WorkflowTasks/types";
import { formatDateAndTime } from "components/utils/date";
import { isTaskCompleted } from "components/WorkflowTasks/utils";
import { Button } from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import cn from "classnames";
import "./WorkflowTask.scss";

interface WorkFlowTaskProps {
    task: WorkFlowTaskModel;
    onTaskClick: (task: WorkFlowTaskModel) => void;
}

export const WorkflowTask = ({ task, onTaskClick }: WorkFlowTaskProps) => {
    let taskTitle = task.stepType;
    let buttonText = task.stepType;
    let buttonIcon: IconProp = "list-check";

    switch (task.stepType) {
        case WorkflowTaskStepType.ApplicationFormFieldEntry:
        case WorkflowTaskStepType.ApplicationFormSectionEntry:
        case WorkflowTaskStepType.ApplicationFormEntry:
            taskTitle = "Submit form info";
            buttonText = "Submit Form";
            buttonIcon = "pen-field";
            break;
        case WorkflowTaskStepType.CreateNewMeasure:
            taskTitle = "Add equipment measure";
            buttonText = "Add Measure";
            buttonIcon = "square-plus";
            break;
        case WorkflowTaskStepType.DocumentUpload:
            taskTitle = "Upload file";
            buttonText = "Upload File";
            buttonIcon = "arrow-up-from-line";
            break;
        default:
            break;
    }

    const clickableContainerProps = isTaskCompleted(task)
        ? {
              role: "button",
              tabIndex: 0,
              onKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => e.key === "Enter" && onTaskClick(task),
              onClick: () => onTaskClick(task),
              "aria-label": taskTitle,
              "aria-describedby": task.wfTaskNumber,
              "data-metadata": JSON.stringify({ name: `Done: ${taskTitle}` }),
          }
        : {};

    return (
        <div
            {...clickableContainerProps}
            aria-haspopup={isTaskCompleted(task) ? "dialog" : undefined}
            className={cn("workflow-task d-flex flex-column border rounded-3 border shadow-sm position-relative px-4 pt-3 pb-3", {
                "mb-2": isTaskCompleted(task),
            })}
        >
            <div className="d-flex align-items-center w-100">
                <div>
                    {isTaskCompleted(task) ? (
                        <FontAwesomeIcon icon={["fal", "circle-check"]} size="lg" color="green" />
                    ) : (
                        <FontAwesomeIcon icon={["fal", "circle-dashed"]} size="lg" />
                    )}
                </div>
                <div className="task-content d-flex justify-content-between w-100 align-items-center">
                    <div className="flex-column">
                        <h3 id={task.wfTaskNumber} className="h5 text-body fw-medium m-0 px-3">
                            {taskTitle}
                        </h3>
                        <p className="text-step m-0 px-3 text-break">{task.step}</p>
                    </div>
                    {!isTaskCompleted(task) && (
                        <Button
                            variant="primary"
                            className="d-flex align-items-center col-auto"
                            onClick={() => onTaskClick(task)}
                            aria-label={taskTitle}
                            aria-haspopup="dialog"
                            aria-describedby={task.wfTaskNumber}
                        >
                            <FontAwesomeIcon icon={["fal", buttonIcon]} className="py-1" />
                            <div className="button-text py-1 d-none d-md-block ps-2">{buttonText}</div>
                        </Button>
                    )}
                </div>
            </div>
            {isTaskCompleted(task) && task?.dateCompleted && (
                <>
                    <hr className="my-2" />
                    <p className="m-0 small">Completed on {formatDateAndTime(task?.dateCompleted)}</p>
                </>
            )}
            <div className={cn("detail position-absolute top-0 start-0 h-100", { complete: isTaskCompleted(task) })} />
        </div>
    );
};
