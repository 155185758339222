import { DataGridColumnConfig } from "components/DataGrid/types";
import { listToAnyOf } from "components/JsonForm/utils";
import { distinct, toArray } from "components/utils/array";
import { dateStringToJsonDate, dateToJson } from "components/utils/date";
import { getUrl, httpDeleteAuthorized, httpPostAuthorized, httpPutAuthorized } from "components/utils/http";
import { isEmpty, isNil, isNumber, orderBy } from "lodash";
import { ReferenceResult } from "types/ReferenceResult";
import {
    EquipmentCategory,
    EquipmentDetailsFromCatalog,
    EquipmentAttribute,
    AttributeLookupValue,
    ApprovedEquipmentCreateResponse,
} from "./types";
import { EquipmentAddSubmitItem } from "types/EquipmentAddSubmitItem";
import { EquipmentEditSubmitItem } from "types/EquipmentEditSubmitItem";
import { ApprovedEquipmentSearchResult } from "./useApprovedEquipmentSearch";

export const ATTRIBUTE_MAX_LENGTH: number = 55;

export const AttributeFieldType = {
    TEXT: "1",
    TEXTAREA: "2",
};

export const isNumericAttributeType = (typeName: string) => {
    return ["numeric", "limitednumeric"].includes((typeName || "").toLowerCase());
};

export const isDateAttributeType = (typeName: string) => {
    return ["date", "date with limits"].includes((typeName || "").toLowerCase());
};

export const getAttributeTitle = (friendlyName: string | undefined, attributeName: string | undefined) => {
    if (!isNil(friendlyName)) {
        return String(friendlyName).toUpperCase();
    }

    return attributeName
        ? String(attributeName)
              .trim()
              .split("_")
              .filter((i) => !isEmpty(i))
              .join(" ")
              .toUpperCase()
        : "&nbsp;";
};

export const getNormalizedAttributeValue = (value: any, validationTypeName: string, isNumericTypeMismatch: boolean = false) => {
    if (isNumericAttributeType(validationTypeName) && !isNumericTypeMismatch) {
        return isNil(value) || isNaN(Number(value)) ? undefined : Number(value);
    }

    return isNil(value) || value === "" ? undefined : String(value);
};

export const getBreadcrumbItems = (categories: EquipmentCategory[], activeCategory: EquipmentCategory) => {
    return categories.map((c) => ({
        name: c.category,
        active: c.categorynumber === activeCategory.categorynumber,
    }));
};

export const getValidationTypeName = (attribute: any, validationTypes: ReferenceResult[]) => {
    return validationTypes.filter((i) => Number(i.val) === Number(attribute.validationType)).map((i) => i.display)[0];
};

/**
 * Get attributes form schema.
 */
export const getSchema = (
    equipment: EquipmentDetailsFromCatalog,
    validationTypes: ReferenceResult[],
    trueFalseTypes: ReferenceResult[]
) => {
    const attributes: EquipmentAttribute[] = toArray(equipment?.attributes ?? []).filter((a: EquipmentAttribute) => a.showAll);

    const attributesSchema = attributes.reduce((result, attribute) => {
        const attributeName = (attribute?.attributeName ?? "").toLowerCase();
        const validationTypeName = getValidationTypeName(attribute, validationTypes);
        const friendlyName = attribute.friendlyName;
        const lookupValues = attribute.lookupValues;
        const lowerLimit = attribute.lowerLimit;
        const upperLimit = attribute.upperLimit;
        const validationOther = attribute.validationOther;

        const attributeSchema = getAttributeSchema(
            attributeName,
            friendlyName,
            validationTypeName,
            lookupValues,
            lowerLimit,
            upperLimit,
            ATTRIBUTE_MAX_LENGTH,
            AttributeFieldType.TEXT,
            validationOther
        );

        return {
            ...result,
            ...attributeSchema,
        };
    }, {});

    const isRequiredId = trueFalseTypes.filter((t) => t.display.toLowerCase() === "true").map((t) => Number(t.val))[0];

    const attributesRequired = attributes
        .filter((attribute) => attribute.validationRequired === isRequiredId)
        .map((attribute) => (attribute.attributeName ?? "").toLowerCase())
        .filter(distinct);

    const quantitySchema = {
        quantity: {
            type: "integer",
            title: equipment?.quantityFriendlyName ?? "Quantity",
        },
    };

    return {
        type: "object",
        required: ["quantity", ...attributesRequired],
        properties: {
            ...quantitySchema,
            ...attributesSchema,
        },
    };
};

export const getAttributeSchema = (
    attributeName: string,
    friendlyName: string | undefined,
    validationTypeName: string,
    lookupValues: any,
    lowerLimit: any,
    upperLimit: any,
    attributeMaxLength: number,
    fieldType: string,
    validationOther: string | undefined
) => {
    const isNumericAttribute = isNumericAttributeType(validationTypeName);
    const isDateAttribute = isDateAttributeType(validationTypeName);

    const numberMinimum = !isNil(lowerLimit) &&
        isNumericAttribute && {
            minimum: Number(lowerLimit),
        };

    const numberMaximum = !isNil(upperLimit) &&
        isNumericAttribute && {
            maximum: Number(upperLimit),
        };

    const dateMinimum = !isNil(lowerLimit) &&
        isDateAttribute && {
            formatMinimum: lowerLimit === "today" ? dateToJson(new Date()) : dateStringToJsonDate(lowerLimit),
        };

    const dateMaximum = !isNil(upperLimit) &&
        isDateAttribute && {
            formatMaximum: upperLimit === "today" ? dateToJson(new Date()) : dateStringToJsonDate(upperLimit),
        };

    const maxLength = attributeMaxLength
        ? {
              maxLength: attributeMaxLength,
          }
        : {};

    const attributeTitle = getAttributeTitle(friendlyName, attributeName);
    const hasLookups = (lookupValues || []).length > 0 && !isDateAttribute;

    let isNumericTypeMismatch = false;
    let type = isNumericAttribute && fieldType !== AttributeFieldType.TEXTAREA ? "number" : "string";

    if (hasLookups && isNumericAttribute && lookupValues.some((item: any) => !isNumber(item.lookup))) {
        isNumericTypeMismatch = true;
        type = "string";
    }

    return {
        [attributeName]: {
            type,
            title: attributeTitle,
            ...numberMinimum,
            ...numberMaximum,
            ...dateMinimum,
            ...dateMaximum,
            ...maxLength,
            ...(hasLookups
                ? {
                      anyOf: listToAnyOf({
                          list: lookupValues,
                          map: (item: AttributeLookupValue) => ({
                              title: item.lookup,
                              enum: [type === "string" ? item.lookup : Number(item.lookup)],
                          }),
                          sort: "",
                      }),
                  }
                : {}),
            isNumericTypeMismatch,
            validationOther,
        },
    };
};

/**
 * Get attributes form ui schema.
 */
export const getUiSchema = (equipment: EquipmentDetailsFromCatalog, validationTypes: ReferenceResult[]) => {
    const attributes: EquipmentAttribute[] = toArray(equipment?.attributes ?? []).filter((a: EquipmentAttribute) => a.showAll);

    const attributesUiSchema = attributes.reduce((result, attribute) => {
        const attributeName = (attribute?.attributeName ?? "").toLowerCase();
        const validationTypeName = getValidationTypeName(attribute, validationTypes);
        const friendlyNameToolTip = attribute.toolTip;
        const lookupValues = attribute.lookupValues;

        const attributeUiSchema = getAttributeUiSchema(
            attributeName,
            validationTypeName,
            friendlyNameToolTip,
            lookupValues,
            attribute.editAll,
            attribute.showAll,
            AttributeFieldType.TEXT
        );

        return {
            ...result,
            ...attributeUiSchema,
        };
    }, {});

    const attributesOrder = orderBy(attributes, [(item) => item.itemOrder])
        .map((attribute) => (attribute?.attributeName ?? "").toLowerCase())
        .filter(distinct);

    return {
        "ui:rootFieldId": "equipment",
        ...attributesUiSchema,
        "ui:order": ["*", "quantity", ...attributesOrder],
    };
};

export const getAttributeUiSchema = (
    attributeName: string,
    validationTypeName: string,
    friendlyNameToolTip: string | undefined,
    lookupValues: AttributeLookupValue[],
    editAll: boolean,
    showAll: boolean,
    fieldType: string
) => {
    let widget = isDateAttributeType(validationTypeName) ? "date" : lookupValues.length > 0 ? "select" : "text";

    if (fieldType === AttributeFieldType.TEXTAREA) {
        widget = "textarea";
    }

    return {
        [attributeName]: {
            "ui:widget": widget,
            "ui:time": false,
            "ui:readonly": !editAll,
            "ui:help": friendlyNameToolTip,
        },
    };
};

export const getApprovedEquipmentRowId = (equipment: ApprovedEquipmentSearchResult | undefined, gridConfig: any) => {
    const key = gridConfig?.columns?.find((c: DataGridColumnConfig) => (c.name ?? "").toLowerCase() === "rowid")?.key;

    if (equipment && key) {
        return Number(equipment[key]);
    }

    return undefined;
};

/**
 * Get attributes form initial values.
 */
export const getInitialValues = (
    equipment: EquipmentDetailsFromCatalog,
    validationTypes: ReferenceResult[],
    values: any,
    schema: any,
    isNewEquipment: boolean = false
) => {
    const attributes: EquipmentAttribute[] = toArray(equipment?.attributes ?? []).filter((a: EquipmentAttribute) => a.showAll);

    const attributeValues = attributes.reduce((result, attribute) => {
        const attributeName = (attribute.attributeName ?? "").toLowerCase();
        const validationTypeName = getValidationTypeName(attribute, validationTypes);
        const isNumericTypeMismatch = schema.properties?.[attributeName]?.isNumericTypeMismatch ?? false;

        const value = getNormalizedAttributeValue(
            values ? values[attributeName] : attribute.value,
            validationTypeName,
            isNumericTypeMismatch
        );

        const defaultValue = getNormalizedAttributeValue(attribute.defaultValue, validationTypeName);

        let attributeValue = value;

        // Use default value only when adding equipment
        if (isNewEquipment) {
            // Apply default value only if form is not touched jet ( values === null )
            attributeValue = value ?? (values === null ? defaultValue : value);
        }

        return {
            ...result,
            [attributeName]: attributeValue,
        };
    }, {});

    return {
        quantity: equipment?.quantity,
        ...attributeValues,
    };
};

/**
 * Create new equipment.
 */
export async function createEquipment(applicationNumber: string, equipmentItem: EquipmentAddSubmitItem, taskNumber?: string) {
    const url = getUrl(process.env.REACT_APP_EQUIPMENT_ENDPOINT, { applicationNumber });
    if (!url) throw new Error("Equipment endpoint is not defined");

    return httpPostAuthorized(url, { ...equipmentItem, taskNumber });
}

/**
 * Update equipment.
 */
export async function updateEquipment(applicationNumber: string, equipmentNumber: string, equipmentItem: EquipmentEditSubmitItem) {
    const url = getUrl(process.env.REACT_APP_EQUIPMENT_ENDPOINT, { applicationNumber }) + `/${equipmentNumber}`;
    return httpPutAuthorized(url, equipmentItem);
}

/**
 * Delete equipment.
 */
export async function deleteEquipment(applicationNumber: string, equipmentNumber: string) {
    const url = getUrl(process.env.REACT_APP_EQUIPMENT_ENDPOINT, { applicationNumber }) + `/${equipmentNumber}`;
    return httpDeleteAuthorized(url);
}

export async function createApprovedEquipment(
    applicationNumber: string,
    industryMeasureNumber: string,
    itemId: number,
    quantity: number
): Promise<ApprovedEquipmentCreateResponse> {
    const query = new URLSearchParams();
    query.append("industryMeasureNumber", industryMeasureNumber);
    query.append("itemId", String(itemId));
    query.append("quantity", String(quantity));

    const url = getUrl(process.env.REACT_APP_APPROVED_EQUIPMENT_ENDPOINT, { applicationNumber }) + "?" + query.toString();
    return httpPostAuthorized(url, undefined);
}

export const validateAttributesForm = (formData: any, errors: any, schema: any) => {
    Object.keys(schema.properties ?? {}).forEach((attributeKey) => {
        if (!isNil(formData[attributeKey])) {
            // Add error if attribute value does not match regex in validationOther
            if (schema.properties[attributeKey].validationOther) {
                let regex;
                const regexString = schema.properties[attributeKey].validationOther;
                if (regexString.startsWith("/")) {
                    // Separate pattern and flags
                    const match = regexString.match(/^\/(.*)\/([gimuy]*)$/);
                    const pattern = match[1];
                    const flags = match[2];
                    regex = new RegExp(pattern, flags);
                } else {
                    regex = new RegExp(regexString);
                }
                if (!regex.test(formData[attributeKey])) {
                    errors[attributeKey].addError(`Must match pattern ${regexString}`);
                }
            }
        }
    });

    return errors;
};
